<template>
  <div class="mod-config">
    <div style="margin-bottom: 15px;width: 100%" v-if="this.dataList.length === 0">
      <el-alert
        type="warning">
        <span style="font-size: 14px">老板，您好！您目前还没有添加企业，添加/认证企业后可以更加方便便捷哦。</span>
        <span class="aspan" @click="addEnterprise">立即添加</span>
      </el-alert>
    </div>
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item>
        <el-input v-model="dataForm.enterpriseName" placeholder="企业名称" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="dataForm.socialCode" placeholder="统一社会信用代码" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-select v-model="dataForm.enterpriseStatus" placeholder="企业状态" clearable>
          <el-option
            v-for="(val, key) in statusDict"
            :key="key"
            :label="val"
            :value="key">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <el-button type="primary" @click="addEnterprise">添加企业</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      style="width: 100%;">
      <el-table-column
        type="index"
        header-align="center"
        align="center"
        width="50">
      </el-table-column>
      <el-table-column
        prop="enterpriseName"
        header-align="center"
        align="center"
        label="企业名称">
      </el-table-column>
      <el-table-column
        prop="corporation"
        header-align="center"
        align="center"
        label="法人">
      </el-table-column>
      <el-table-column
        prop="socialCode"
        header-align="center"
        align="center"
        label="社会统一信用代码">
      </el-table-column>
      <el-table-column
        prop="enterpriseState"
        header-align="center"
        align="center"
        label="企业状态">
        <template slot-scope="scope" >
          <el-tag :type="enterpriseStatusDict[scope.row.enterpriseState]">{{enterpriseStatusDict[scope.row.enterpriseState]}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="approveMsg"
        header-align="center"
        align="center"
        label="审核意见">
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        label="操作">
        <template slot-scope="scope">
          <span v-if="scope.row.enterpriseStatus === '0'" style="color: #409EFF;margin-right: 5px">审核中...</span>
          <el-button type="text" size="small" v-if="scope.row.enterpriseStatus !== '0'" @click="infoEnterprise(scope.row)">查看</el-button>
          <el-button type="text" size="small" v-if="scope.row.enterpriseStatus !== '0'" @click="deleteHandle(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <enterprise-add v-if="addVisible" ref="addEnt" @closeCallback="closeCallback"/>
    <enterprise-info v-if="infoVisible" ref="infoEnt" @closeCallback="closeCallback"/>
  </div>
</template>

<script>
  import EnterpriseAdd from './enterprise-add'
  import EnterpriseInfo from './enterprise-info'
  export default {
    data () {
      return {
        dataForm: {
          enterpriseName: '',
          socialCode: '',
          businessCode: '',
          enterpriseStatus: ''
        },
        dataList: [],
        tagType: {0: '', 1: 'success', 2: 'danger'},
        statusDict: {},
        enterpriseStatusDict: {},
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false,
        addVisible: false,
        infoVisible: false
      }
    },
    components: {
      EnterpriseAdd,
      EnterpriseInfo
    },
    created () {
      this.$getDictMap('AUDIT_STATUS,ENTERPRISE_STATUS', (dict) => {
        this.statusDict = dict.AUDIT_STATUS
        this.enterpriseStatusDict = dict.ENTERPRISE_STATUS
      })
      this.getDataList()
    },
    methods: {
      // 获取数据列表
      getDataList (reset) {
        this.dataListLoading = true
        if (reset) {
          this.pageIndex = 1
        }
        this.$http({
          url: this.$http.adornUrl(this.$api.U.ENTERPRISE.LIST),
          method: 'get',
          params: this.$http.adornParams({
            'page': this.pageIndex,
            'limit': this.pageSize,
            'enterpriseName': this.dataForm.enterpriseName,
            'socialCode': this.dataForm.socialCode,
            'businessCode': this.dataForm.businessCode,
            'enterpriseStatus': this.dataForm.enterpriseStatus
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增
      addEnterprise () {
        this.addVisible = true
        this.$nextTick(() => {
          this.$refs['addEnt'].init()
        })
      },
      infoEnterprise (info) {
        this.infoVisible = true
        this.$nextTick(() => {
          this.$refs['infoEnt'].init(info)
        })
      },
      closeCallback (refresh) {
        this.addVisible = false
        this.infoVisible = false
        if (refresh) {
          this.getDataList(true)
        }
      },
      // 删除
      deleteHandle (id) {
        this.$confirm('确定删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl(this.$api.U.ENTERPRISE.DELETE),
            method: 'post',
            params: this.$http.adornParams({
              'id': id
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList(true)
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    }
  }
</script>
<style scoped>
  .aspan {
    font-weight: bold;
    color: #409EFF;
    cursor: pointer;
    font-size: 15px;
  }
</style>
